import { Select } from 'antd';
import { useApp } from '../../store/appStores';
import { useCurrentTpv } from '../../utils/hooks/useCurrentTpv';

export const ServiceSelector = () => {
  const set = useApp((s) => s.set);
  const { services = [] } = useCurrentTpv();
  const selectedService = useApp((s) => s.selectedService);

  if (!services.length) return null;

  return (
    <Select
      style={{ width: 200 }}
      onChange={(selectedService) => {
        set({ selectedService });
      }}
      value={selectedService}
      options={services.map((x) => ({ label: x.name, value: x._id }))}
    />
  );
};
