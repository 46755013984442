import styled from 'styled-components';
import { DashboardItem } from '../../components/layout/DashboardItem';
import { useGetValidRoutes } from '../../utils/hooks/useGetValidRoutes';

export const SelectPage = ({ pages }) => {
  const getValidRoutes = useGetValidRoutes();
  const routes = getValidRoutes(pages);

  return (
    <Wrapper>
      {routes.map(([path, route]) => {
        return (
          <DashboardItem
            key={path}
            path={path}
            image={`/img/app_imgs/${route.image}.png`}
            title={route.title}
            count={route.count}
          />
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 32px;
  padding: 16px;
`;
