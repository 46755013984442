import { Radio } from 'antd';
import en from '../constants/lang/en.json';
import { useApp } from '../store/appStores';

const captions = { en };

export const t = (msg) => {
  const lang = localStorage.getItem('app-lang')?.toLowerCase();

  return captions[lang]?.[msg] ?? msg;
};

export const LangSelector = () => {
  const set = useApp((s) => s.set);
  const lang = useApp((s) => s.lang);

  return (
    <Radio.Group
      style={{ marginInline: 'auto', display: 'block', textAlign: 'center' }}
      value={lang}
      onChange={(event) => {
        const lang = event.target.value;
        set({ lang });
        localStorage.setItem('app-lang', lang);
      }}
    >
      {Object.entries(langs).map(([value, { img, label }]) => {
        return (
          <Radio.Button value={value} key={value} style={{ padding: 4 }}>
            <img
              alt={label}
              style={{ borderRadius: 4, height: '100%', width: '100%', objectFit: 'cover' }}
              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${img}.svg`}
            />
          </Radio.Button>
        );
      })}
    </Radio.Group>
  );
};

const langs = {
  es: {
    img: 'ES',
    label: 'Español',
  },
  en: {
    img: 'GB',
    label: 'English',
  },
};
