import styled from 'styled-components';
import { LangSelector } from '../../components/LangProvider';
import { useApp, useStore } from '../../store/appStores';
import { AppRoutes } from '../../constants/routes';
import { AppModel, AppRole } from '../../constants/api';
import { useNavigate } from 'react-router-dom';
import { useCurrentTpv } from '../../utils/hooks/useCurrentTpv';
import { NoTpvAccess } from './NoTpvAccess';
import { SelectTpv } from './SelectTpv';
import { OpenWorkingDay } from './OpenWorkingDay';
import { SelectPage } from './SelectPage';
import { useFindWorkingDay } from '../../utils/hooks/useFindWorkingDay';
import { CloseWorkingDayButton } from './CloseWorkingDayButton';
import { SelectService } from './SelectService';
import { UnselectTpvButton } from './UnselectTpvButton';
import dayjs from 'dayjs';
import { WorkingDayToClose } from './WorkingDayToClose';

export default function Dashboard({ pages = AppRoutes }) {
  const ignoreClosedWorkingDay = useApp((s) => s.ignoreClosedWorkingDay);

  const navigate = useNavigate();

  const userInfo = useApp((s) => s.userInfo);
  const selectedService = useApp((s) => s.selectedService);
  const selectedWorkingDay = useApp((s) => s.selectedWorkingDay);
  const workingDays = useStore(AppModel.WORKING_DAY);
  const tpvs = useStore(AppModel.TPV);

  const currentTpv = useCurrentTpv();

  useFindWorkingDay();

  if (!tpvs.length && userInfo.role === AppRole.ADMIN) {
    navigate('/settings/tpv');

    return null;
  }

  const tpvIds = tpvs.map((x) => x._id);
  const hasTpvAccess = userInfo.tpvs.filter((x) => tpvIds.some((y) => x === y)).length;

  if (!hasTpvAccess) {
    return <NoTpvAccess />;
  }

  if (!currentTpv) {
    return <SelectTpv />;
  }

  if (!selectedService) {
    return <SelectService />;
  }

  if (!selectedWorkingDay) {
    return <OpenWorkingDay />;
  }

  const workingDay = workingDays.find((x) => x._id === selectedWorkingDay);
  const workDate = dayjs(workingDay?.openDate);

  const isSameOpenedDay = workDate.isSame(dayjs(), 'day');

  if (!isSameOpenedDay && !ignoreClosedWorkingDay) {
    return <WorkingDayToClose />;
  }

  return (
    <>
      <SelectPage pages={pages} />
      <br />
      <LangSelector />
      <DashboardActionsWrapper>
        <CloseWorkingDayButton />
        <UnselectTpvButton />
      </DashboardActionsWrapper>
    </>
  );
}

export const DashboardActionsWrapper = styled.footer`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  max-width: 150px;
  margin-inline: auto !important;
`;
