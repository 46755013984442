import { Button, Form, Modal, Space, message } from 'antd';
import { useTickets } from '../store/appStores';
import { useForm } from 'antd/es/form/Form';
import { FormField } from './Forms/FormField';
import { Actions } from '../actions';
import { MailOutlined, PrinterOutlined } from '@ant-design/icons';
import { useEffect } from 'react';

export const PrintingInvoiceForm = () => {
  const set = useTickets((s) => s.set);
  const selectedTicket = useTickets((s) => s.selectedTicket);
  const invoiceModalVisible = useTickets((s) => s.invoiceModalVisible);

  const [form] = useForm();

  const handleClose = () => {
    set({ selectedTicket: null, invoiceModalVisible: false });
  };

  const printInvoice = async () => {
    const clientData = await form.validateFields().catch(() => null);
    if (!clientData) return;

    Actions.post(`/ticket/${selectedTicket}/invoice/print`, { clientData });
    set({ selectedTicket: null });
  };

  const sendPerEmail = async () => {
    const clientData = await form.validateFields().catch(() => null);

    if (!clientData) return;

    if (!clientData.email) {
      message.error('Introduce un email válido');
      return;
    }
    Actions.post(`/ticket/${selectedTicket}/invoice/mail`, { clientData });
    printInvoice();
  };

  useEffect(() => {
    form.resetFields();
  });

  return (
    <Modal
      open={invoiceModalVisible}
      onOk={form.submit}
      onCancel={handleClose}
      footer={
        <Space>
          <Button type="primary" icon={<PrinterOutlined />} onClick={printInvoice}>
            Imprimir
          </Button>
          <Button icon={<MailOutlined />} onClick={sendPerEmail}>
            Enviar por email
          </Button>
        </Space>
      }
    >
      <Form form={form} layout="vertical">
        <FormField.Text label="Nombre" name="name" required={true} />
        <FormField.Text label="DNI/NIF" name="identificationNumber" required={true} />
        <FormField.Text label="Dirección" name="address" required={true} />
        <FormField.Text label="Correo electrónico" name="email" rules={[{ type: 'email' }]} />
      </Form>
    </Modal>
  );
};
