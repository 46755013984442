import { Form, Modal } from 'antd';
import { t } from '../LangProvider';
import { Suspense, lazy, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { FormField } from './FormField';
import { AppModel } from '../../constants/api';
import { useApp } from '../../store/appStores';
import { Actions } from '../../actions';
import { DeleteButton } from '..';
import { getBase64 } from '../../utils';

export const AppForm = () => {
  const { id: formId, data: formData } = useApp((s) => s.currentForm ?? {});

  const set = useApp((s) => s.set);
  const upsert = useApp((s) => s.upsert);
  const deleteMany = useApp((s) => s.delete);

  const form = Form.useForm()[0];

  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    const formatedValues = formatDates(formData);
    setInitialValues(formatedValues);
  }, [formData]);

  useEffect(() => {
    form.resetFields();
  });

  const handleFinish = async (formData) => {
    const data = parseFinalResult(formData);
    let result;

    if (data.imageUrl instanceof File) {
      data.imageUrl = await getBase64(data.imageUrl);
    }

    if (formData?._id) {
      result = await Actions.put(`/${formId}/${formData._id}`, data);
    } else {
      result = await Actions.post(`/${formId}`, data);
    }

    if (!result) return;

    upsert(formId, result);
    set({ currentForm: { id: null, data: {} } });
  };

  const Content = FormContent[formId] ?? (() => 'Formulario no encontrado');

  return (
    <Modal
      open={Boolean(formId)}
      title={t(formData?._id ? 'Editar' : 'Crear')}
      onCancel={() => set({ currentForm: { id: null, formData: {} } })}
      onOk={form.submit}
      forceRender
      zIndex={1010}
      maskClosable={false}
    >
      <Form form={form} layout="vertical" initialValues={initialValues} onFinish={handleFinish}>
        <FormField.Text name={'_id'} hidden={true} />
        <FormField.Text name={'establishment'} hidden={true} />
        <Suspense fallback={'Cargando...'}>{Content ? <Content form={form} /> : null}</Suspense>
      </Form>
      {formData?._id ? (
        <DeleteButton
          onConfirm={async () => {
            const ids = [formData._id];
            const deleted = await Actions.delete(`/${formId}`, ids);

            if (!deleted) return;

            deleteMany(formId, ids);
            set({ currentForm: { id: null, data: {} } });
          }}
        />
      ) : null}
    </Modal>
  );
};

function formatDates(obj = {}) {
  if (obj instanceof Array) {
    return Object.values(obj).map(formatDates);
  }

  if (obj && typeof obj === 'object') {
    const entries = Object.entries(obj).map(formatValues);
    return Object.fromEntries(entries);
  }

  return obj;
}

function formatValues([key, value]) {
  const keyWord = key.toLowerCase();
  const isDateField =
    keyWord.includes('date') || keyWord.includes('time') || ['createdat', 'updatedat'].includes(keyWord);

  if (isDateField) {
    return [key, parseDate(value)];
  }

  return [key, formatDates(value)];
}

function parseDate(string) {
  const date = dayjs(string);
  if (date.isValid()) {
    return date;
  }
  return string;
}

function parseFinalResult(formData) {
  Object.keys(formData).forEach((key) => {
    if (key !== '_id' && formData[key] === undefined) {
      formData[key] = null;
    }
  });

  return formData;
}

const FormContent = {
  [AppModel.PLATE]: lazy(() => import('./PlateFormContent')),
  [AppModel.PRODUCT]: lazy(() => import('./ProductFormContent')),
  [AppModel.ITEM_TYPE]: lazy(() => import('./ItemTypeFormContent')),
  [AppModel.TABLE]: lazy(() => import('./TableFormContent')),
  [AppModel.ZONE]: lazy(() => import('./ZoneFormContent')),
  [AppModel.TPV]: lazy(() => import('./TpvFormContent')),
  [AppModel.RESOURCE]: lazy(() => import('./ResourceFormContent')),
  [AppModel.RESERVATION]: lazy(() => import('./ReservationFormContent')),
  [AppModel.SPA_RESERVATION]: lazy(() => import('./SpaReservationFormContent')),
  [AppModel.WAREHOUSE]: lazy(() => import('./WarehouseFormContent')),
  [AppModel.WAREHOUSE_ITEM]: lazy(() => import('./WarehouseItemFormContent')),
  [AppModel.PAYMENT_METHOD]: lazy(() => import('./PaymentMethodFormContent')),
  [AppModel.IDENTIFICATION]: lazy(() => import('./IdentificationFormContent')),
  [AppModel.TURN]: lazy(() => import('./TurnFormContent')),
  [AppModel.REGIME]: lazy(() => import('./RegimeFormContent')),
};
