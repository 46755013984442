import { UnlockOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { Actions } from '../../actions';
import { AppModel } from '../../constants/api';
import { useApp } from '../../store/appStores';
import { useState } from 'react';

export const OpenWorkingDayButton = () => {
  const [loading, setLoading] = useState(false);
  const set = useApp((s) => s.set);
  const upsert = useApp((s) => s.upsert);
  const selectedTpv = useApp((s) => s.selectedTpv);

  const handleOpen = async () => {
    setLoading(true);
    const result = await Actions.post('/workingDay', { tpv: selectedTpv });
    if (!result) {
      setLoading(false);
      return;
    }

    upsert(AppModel.WORKING_DAY, result);
    set({ selectedWorkingDay: result._id });
    localStorage.setItem('workingDay', result._id);
    setLoading(false);
  };

  return (
    <Popconfirm onConfirm={handleOpen} title="¿Realmente desea abrir caja?">
      <Button type="primary" icon={<UnlockOutlined />} loading={loading}>
        Abrir caja
      </Button>
    </Popconfirm>
  );
};
