import { ConfigProvider } from 'antd';
import { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { MainLayout } from './components/layout';
import { AppRoutes } from './constants/routes';
import Dashboard from './pages/Dashboard';
import { SocketProvider } from './components/SocketProvider';
import { locale, loadMessages } from 'devextreme/localization';
import { useApp } from './store/appStores';
import { useLoadInitialData } from './utils/hooks/useLoadInitialData';
import { DataLoader } from './components/DataLoader';
import { useGetValidRoutes } from './utils/hooks/useGetValidRoutes';
import { useIsMobile } from './utils/hooks/device';

import devEs from 'devextreme/localization/messages/es.json';
import devEn from 'devextreme/localization/messages/en.json';
import antdLocale from 'antd/locale/es_ES';

import 'dayjs/locale/es';
import './index.css';
import dayjs from 'dayjs';

const devLangs = { es: devEs, en: devEn };

export const App = () => {
  const dataLoaded = useApp((s) => s.dataLoaded);

  const getValidRoutes = useGetValidRoutes();

  useEffect(() => {
    const lang = localStorage.getItem('app-lang')?.toLowerCase() ?? 'es';

    locale(lang);
    dayjs.locale(lang);
    loadMessages(devLangs[lang] ?? devEs);
  }, []);

  useLoadInitialData();
  useIsMobile();

  const routes = [];

  const addRoutes = (initialPath = '', [path, { component: Component, pages, isDashboard }]) => {
    const elProps = {};

    if (isDashboard) {
      elProps.pages = pages;
    }

    const currentPath = `${initialPath}/${path}`;

    routes.push(<Route key={currentPath} path={currentPath} element={<Component {...elProps} />} />);
    getValidRoutes(pages).forEach((entry) => addRoutes(currentPath, entry));
  };

  getValidRoutes(AppRoutes).forEach((entry) => addRoutes('', entry));

  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#c27' } }} locale={antdLocale}>
      <SocketProvider>
        <MainLayout>
          <Suspense fallback="Cargando...">
            <Routes>
              {dataLoaded ? (
                <>
                  {routes}
                  <Route path="/" Component={Dashboard} />
                  <Route path="/*" Component={() => <Navigate to={'/'} relative="path" replace={true} />} />
                </>
              ) : (
                <Route path="/*" Component={DataLoader} />
              )}
            </Routes>
          </Suspense>
        </MainLayout>
      </SocketProvider>
    </ConfigProvider>
  );
};
