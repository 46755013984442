import { Button, Popconfirm } from 'antd';
import { Actions } from '../../actions';
import { AppModel } from '../../constants/api';
import { LockOutlined } from '@ant-design/icons';
import { useApp } from '../../store/appStores';
import { t } from '../../components/LangProvider';
import { useState } from 'react';
import axios from 'axios';

export const CloseWorkingDayButton = () => {
  const [loading, setLoading] = useState(false);
  const set = useApp((s) => s.set);
  const selectedWorkingDay = useApp((s) => s.selectedWorkingDay);

  const handleCloseWorkingDay = () => {
    setLoading(true);
    axios
      .post(`/workingday/${selectedWorkingDay}/close`)
      .then(async ({ data }) => {
        if (!data) return;
        await Actions.get(AppModel.WORKING_DAY, { isClosed: false }).then((workingDays) => {
          set({ [AppModel.WORKING_DAY]: workingDays, selectedWorkingDay: null });
        });
      })
      .catch(() => null)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Popconfirm onConfirm={handleCloseWorkingDay} title="¿Realmente desea cerrar caja?">
      <Button loading={loading} danger icon={<LockOutlined />}>
        {t('Cerrar caja')}
      </Button>
    </Popconfirm>
  );
};
