import { create } from 'zustand';
import { clone, keyBy } from '../utils';
import { AppModel, TicketType } from '../constants/api';
import { Actions } from '../actions';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import axios from 'axios';
import { OrderLineType } from '../constants';
import { useMemo } from 'react';

export const useApp = create((set, get) => ({
  // APP
  loading: false,
  isMobile: window.innerWidth <= 768,
  token: null,
  dataLoaded: false,
  selectedTpv: null,
  selectedService: null,
  selectedWorkingDay: null,
  currentForm: { id: null, data: {} },
  ignoreClosedWorkingDay: false,
  lang: localStorage.getItem('app-lang') ?? navigator.language,
  loadData: async () => {
    set({ loading: true });
    const profile = await Actions.get('/user/profile');
    if (!profile) {
      set({ loading: false });
      localStorage.removeItem('token');
      window.location.reload();
      return;
    }

    await Promise.all(initialFetch.map((x) => Actions.get(...x)))
      .then((data) => {
        data.forEach((data, index) => {
          set({ [initialFetch[index][0]]: data });
        });
        set({ dataLoaded: true, profile });
      })
      .catch(() => {
        console.log('Error cargando el perfil y el establecimiento');
      })
      .finally(() => {
        set({ loading: false });
      });
  },
  getObjById(model, id) {
    const dataSource = get()[model];
    return (dataSource ?? []).find((x) => x._id === id);
  },

  // USER DATA
  profile: null,
  userInfo: null,
  establishment: null,

  // GLOBAL MODELS
  [AppModel.APP_EXTENSION]: [],
  [AppModel.WORKING_DAY]: [],
  [AppModel.TPV]: [],
  [AppModel.USER]: [],
  [AppModel.PAYMENT_METHOD]: [],
  [AppModel.ITEM_TYPE]: [],

  //#region TPV TYPES
  // TYPE: RESTAURANT
  [AppModel.TABLE]: [],
  [AppModel.PLATE]: [],
  [AppModel.RESERVATION]: [],
  [AppModel.ORDER]: [],
  [AppModel.ZONE]: [],
  [AppModel.TICKET]: [],

  // TYPE: SHOP
  [AppModel.PRODUCT]: [],
  [AppModel.STOCK]: [],

  // TYPE: SPA
  [AppModel.SPA_RESERVATION]: [],
  //#endregion

  //#region EXTENSIONS
  // EXTENSION: DIARY
  [AppModel.EVENT]: [],

  // EXTENSION: RESOURCES
  [AppModel.RESOURCE]: [],

  // EXTENSION: WAREHOUSE
  [AppModel.WAREHOUSE]: [],
  [AppModel.WAREHOUSE_ITEM]: [],

  // EXTENSION: PMS
  [AppModel.GUEST]: [],
  [AppModel.REGIME]: [],
  //#endregion

  upsert(key, data) {
    const joinedData = { ...keyBy(get()[key]), ...keyBy(data) };
    set({ [key]: Object.values(joinedData) });
  },
  delete(key, ids) {
    set({ [key]: get()[key].filter((x) => !ids.includes(x._id)) });
  },
  set,
}));

export const useStore = (model) => useApp((s) => s[model] ?? []);

export const useTpvUsers = () => {
  const selectedTpv = useApp((s) => s.selectedTpv);
  const users = useStore(AppModel.USER);

  return useMemo(() => {
    return users.filter((user) => user.userInfo?.tpvs?.includes(selectedTpv));
  }, [users, selectedTpv]);
};

export const useCoffeeShop = create((set, get) => ({
  pendingOrders: [],
  currentOrder: [],
  editingLine: null,

  upsertLine: (newLine) => {
    const { currentOrder } = get();

    if (newLine.tempId != null) {
      const index = currentOrder.findIndex((x) => x.tempId === newLine.tempId);
      const newCurrentOrder = [...currentOrder];
      newCurrentOrder[index] = newLine;

      set({ currentOrder: newCurrentOrder });
      return;
    }

    const index = currentOrder.findIndex((x) => {
      if (x.item !== newLine.item) return false;

      const existingLineExtras = x.details?.addons ?? [];
      const newLineExtras = newLine.details?.addons ?? [];

      const mixedAddons = new Set([...existingLineExtras, ...newLineExtras]);

      const sameAddonsCount = existingLineExtras.length === newLineExtras.length;
      return sameAddonsCount && mixedAddons.size === existingLineExtras.length;
    });

    if (index === -1) {
      set({ currentOrder: [...currentOrder, { ...newLine, tempId: nanoid() }] });
      return;
    }

    const existingLine = currentOrder[index];

    const existingLineExtras = existingLine.details?.addons ?? [];
    const newLineExtras = newLine.details?.addons ?? [];

    const mixedAddons = new Set([...existingLineExtras, ...newLineExtras]);

    const sameAddonsCount = existingLineExtras.length === newLineExtras.length;
    const hasSameAddons = sameAddonsCount && mixedAddons.size === existingLineExtras.length;

    if (hasSameAddons) {
      const newCurrentOrder = [...currentOrder];
      newCurrentOrder[index].quantity += newLine.quantity;
      set({ currentOrder: newCurrentOrder });
      return;
    }

    set({ currentOrder: [...currentOrder, { ...newLine, tempId: nanoid() }] });
  },

  set,
}));

export const useShop = create((set, get) => ({
  pendingOrders: [],
  currentOrder: [],
  editingLine: null,
  currentReservation: null,
  currentReservationModel: undefined,

  upsertLine: (newLine) => {
    const { currentOrder } = get();

    if (newLine.tempId != null) {
      const index = currentOrder.findIndex((x) => x.tempId === newLine.tempId);
      const newCurrentOrder = [...currentOrder];
      newCurrentOrder[index] = newLine;

      set({ currentOrder: newCurrentOrder });
      return;
    }

    const index = currentOrder.findIndex((x) => x.item === newLine.item);

    if (index === -1) {
      set({ currentOrder: [...currentOrder, { ...newLine, tempId: nanoid() }] });
      return;
    }

    const existingLine = currentOrder[index];

    const hasSameVariant = newLine.variant === existingLine.variant;
    const isSameSize = newLine.size === existingLine.size;

    const existingLineExtras = existingLine.options ?? [];
    const newLineExtras = newLine.options ?? [];

    const mixedExtras = new Set([...existingLineExtras, ...newLineExtras]);

    const sameExtrasCount = existingLineExtras.length === newLineExtras.length;
    const hasSameExtras = sameExtrasCount && mixedExtras.size === existingLineExtras.length;

    if (hasSameVariant && isSameSize && hasSameExtras) {
      const newCurrentOrder = [...currentOrder];
      newCurrentOrder[index].quantity += newLine.quantity;
      set({ currentOrder: newCurrentOrder });
      return;
    }

    set({ currentOrder: [...currentOrder, { ...newLine, tempId: nanoid() }] });
  },

  set,
}));

export const useRestaurant = create((set) => ({
  selectedZone: null,
  selectedTable: null,
  showTableDrawer: false,
  tempOrder: null,
  tempOrderLine: null,
  orderLineType: OrderLineType.STARTERS,
  set,
}));

export const useReservations = create((set) => ({
  viewType: 'calendar',
  mode: 'month',
  date: dayjs(),
  selectedReservation: null,
  resources: [],
  employees: [],

  tableSelectorVisible: false,

  acceptedReservationsVisible: false,
  acceptedReservationsDate: null,

  requestedReservationsVisible: false,
  requestedReservationsDate: null,
  set,
}));

export const useTransfer = create((set) => ({
  loading: false,
  lines: [],
  selectedOrder: null,
  showTransferDrawer: false,
  selectedTable: null,
  set,
}));

export const useHotel = create((set) => ({
  client: [],
  regime: [],
  set,
}));

export const useKitchen = create((set) => ({
  type: null,
  pinnedLines: {},
  pausedLines: {},
  pinned: [],
  paused: [],
  set,
}));

export const useCharge = create((set, get) => {
  const defaultConfig = {
    loading: false,
    opened: false,
    hasTips: false,
    discount: {
      amount: 0,
      type: 'PERCENTAGE',
    },
    paymentLines: [],
    openDividePayment: false,
    discountModalVisible: false,
    payOnePartVisible: false,
    paid: null,
    paymentMethod: null,
    hotelReservation: null,
    invoice: null,
    paidLines: [],
    ticket: null,
    disabled: false,
    identification: null,
  };

  return {
    ...clone(defaultConfig),
    reset(close = true) {
      set({ ...clone(defaultConfig), opened: !close });
    },
    set,
  };
});

const initialFetch = [
  ['userInfo'],
  ['establishment'],
  [AppModel.TPV],
  [AppModel.USER],
  [AppModel.APP_EXTENSION],
  [AppModel.PAYMENT_METHOD],
  [AppModel.IDENTIFICATION],
  [AppModel.HOTEL_ROOM],
  [AppModel.HOTEL_RESERVATION],
  [AppModel.REGIME],
  [AppModel.WORKING_DAY, { isClosed: false }],
];

export const useTickets = create((set, get) => ({
  loading: false,
  dataSource: [],
  totalCount: 0,
  currentPage: 0,
  pageSize: 10,
  search: '',
  selectedTicket: null,
  emailRequired: false,

  invoiceModalVisible: false,
  cancelModalVisible: false,
  rectifyModalVisible: false,
  date: null,
  paymentMethods: [],
  selectedTable: null,
  hotelReservation: null,
  openInvoiceForm(selectedTicket, emailRequired = false) {
    set({ selectedTicket, emailRequired });
  },
  fetchTickets(tpv) {
    const { search, currentPage, pageSize, date, paymentMethods, selectedTable, hotelReservation } = get();
    set({ loading: true });

    const filter = { type: TicketType.FINAL, tpv };

    if (paymentMethods.length > 0) {
      filter['paymentLines.paymentMethod'] = { $in: paymentMethods ?? [] };
    }

    if (selectedTable != null) {
      filter.table = selectedTable;
    }

    if (hotelReservation != null) {
      filter['paymentLines.hotelReservation'] = hotelReservation;
    }

    const searchValue = search.trim();

    if (searchValue !== '') {
      filter.number = { $regex: searchValue, $options: 'i' };
    }

    if (date) {
      filter.date = { $gte: dayjs(date).startOf('day'), $lt: dayjs(date).endOf('day') };
    }

    const skip = currentPage * pageSize;

    axios
      .get('/ticket', {
        params: {
          filter: JSON.stringify(filter),
          paginate: true,
          skip,
          take: pageSize,
          sort: JSON.stringify({ createdAt: -1 }),
        },
      })
      .then(({ data }) => {
        if (data) {
          set({ dataSource: data.data, totalCount: data.totalCount });
        } else {
          set({ dataSource: [], totalCount: 0 });
        }
      })
      .finally(() => {
        set({ loading: false });
      });
  },
  set,
}));
