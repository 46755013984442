import { EyeInvisibleOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';
import dayjs from 'dayjs';
import { CloseWorkingDayButton } from './CloseWorkingDayButton';
import { AppModel } from '../../constants/api';
import { useApp, useStore } from '../../store/appStores';
import { useCurrentWorkingDay } from '../../utils/hooks/useCurrentWorkingDay';

export const WorkingDayToClose = () => {
  const users = useStore(AppModel.USER);
  const workingDay = useCurrentWorkingDay();
  const workDate = dayjs(workingDay?.openDate);
  const set = useApp((s) => s.set);

  const handleIgnoreClosedWorkingDay = () => {
    set({ ignoreClosedWorkingDay: true });
  };

  return (
    <Result
      status={'warning'}
      title={
        <div>
          <div>No se ha cerrado la caja del día {workDate.format('DD/MM/YYYY')}</div>
          <div>({users.find((x) => x._id === workingDay.createdBy)?.name})</div>
        </div>
      }
      extra={
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8, alignItems: 'center' }}>
          <CloseWorkingDayButton />
          <Button icon={<EyeInvisibleOutlined />} type="default" onClick={handleIgnoreClosedWorkingDay}>
            Seguir trabajando
          </Button>
        </div>
      }
    />
  );
};
