import { Spin } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { KUARA_APPS_URL } from '../constants/api';
import { useEffect } from 'react';
import { useApp } from '../store/appStores';

export const DataLoader = () => {
  const set = useApp((s) => s.set);
  const [params] = useSearchParams();
  const token = params.get('token') ?? localStorage.getItem('token');

  useEffect(() => {
    if (token) {
      set({ token });
    } else {
      window.location.href = `${KUARA_APPS_URL}/login?from=${window.location.href}`;
    }
  }, [set, token]);

  return (
    <div style={{ display: 'grid', placeContent: 'center', height: '100%' }}>
      <Spin />
    </div>
  );
};
