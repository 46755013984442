import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { API_URL, AppModel } from '../constants/api';
import { FlexSpace } from './styles';
import { useApp, useStore } from '../store/appStores';
import { Actions } from '../actions';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const deleteMany = useApp((s) => s.delete);
  const set = useApp((s) => s.set);
  const upsert = useApp((s) => s.upsert);
  const selectedTpv = useApp((s) => s.selectedTpv);
  const [socket, setSocket] = useState();
  const profile = useApp((s) => s.profile);
  const tables = useStore(AppModel.TABLE);

  useEffect(() => {
    if (profile && selectedTpv && !socket) {
      // Conectamos al establecimiento
      const url = `${API_URL}/${profile.establishment}`;
      const socket = io(url);

      setSocket(socket);

      // Registramos el cliente en la mesa correspondiente

      socket.on('connect', () => {
        socket.emit('register-waiter', { tpv: selectedTpv });
        console.log('📡 SOCKET CONNECTED');
      });

      socket.on('disconnect', () => {
        console.log('DISCONNECTED');
      });
    }
  }, [profile, selectedTpv, socket]);

  useEffect(() => {
    if (socket) {
      socket.on('table-request', (data) => {
        console.log('table-request', data);
      });

      socket.on('call-waiter', (data) => {
        const handleAccept = () => {
          notification.destroy(data.table);
          socket.emit('accept-request', data);
        };

        const handleRefuse = () => {
          notification.destroy(data.table);
          socket.emit('refuse-request', data);
        };

        if (window.navigator.vibrate) {
          window.navigator.vibrate([200, 100, 200]);
        }
        try {
          var audio = new Audio('/notification.wav');
          audio.play();
        } catch {}

        notification.open({
          type: 'info',
          message: `Mesa "${tables.find((x) => x._id === data.table)?.name ?? 'ERROR'}"`,
          description: 'Solicita camarero',
          btn: (
            <FlexSpace>
              <Button type="primary" icon={<CheckOutlined />} onClick={handleAccept} />
              <Button type="dashed" danger icon={<CloseOutlined />} onClick={handleRefuse} />
            </FlexSpace>
          ),
          duration: 0,
          key: data.table,
        });
      });

      socket.on('delete-notification', ({ table }) => notification.destroy(table));

      socket.on('order', (order) => {
        console.log('ORDER', { order });
        upsert(AppModel.ORDER, order);
      });

      socket.on('delete-order', (orderId) => deleteMany(AppModel.ORDER, [orderId]));

      socket.on('error', (error) => {
        console.log({ error });
      });

      socket.on('quohotel-syncreservations', () => {
        console.log('Sincronizando habitaciones...');
        Actions.get('/hotelReservation', { status: 'ACTIVE' }).then((data) => {
          if (!data) return;
          set({ [AppModel.HOTEL_RESERVATION]: data });
        });
      });
      socket.on('sync-reservations', () => {
        console.log('Sincronizando habitaciones...');
        Actions.get('/hotelReservation', { status: 'ACTIVE' }).then((data) => {
          if (!data) return;
          set({ [AppModel.HOTEL_RESERVATION]: data });
        });
      });
    }
    return () => {
      if (socket) {
        socket.removeAllListeners();
      }
    };
  }, [socket, tables, profile, upsert, deleteMany, set]);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
