export const VatType = {
  21: '21%',
  10: '10%',
  4: '4%',
  0: 'Exento',
};

export const TpvType = {
  RESTAURANT: 'RESTAURANT',
  SHOP: 'SHOP',
  SPA: 'SPA',
  COFFEE: 'COFFEE',
};

export const EstablishmentType = {
  HOTEL: 'hotel',
};

export const DATE_FORMAT = 'YYYY-MM-DD';

export const OrderLineType = {
  STARTERS: 'STARTERS',
  FIRST: 'FIRST',
  SECONDS: 'SECONDS',
  DESSERTS: 'DESSERTS',
};

export const OrderLineTypeCaption = {
  [OrderLineType.STARTERS]: 'Entrantes',
  [OrderLineType.FIRST]: 'Primeros',
  [OrderLineType.SECONDS]: 'Segundos',
  [OrderLineType.DESSERTS]: 'Postres',
};

export const OrderLineTypeDataSource = Object.entries(OrderLineTypeCaption).map(([value, label]) => ({ value, label }));

export const OrderLineStatus = {
  PENDING: 'PENDING',
  COOKING: 'COOKING',
  COOKED: 'COOKED',
  SERVED: 'SERVED',
  PAID: 'PAID',
};

export const Nature = {
  DRINK: 'DRINK',
  HOT: 'HOT',
  COLD: 'COLD',
};

export const PaymentMethod = {
  CASH: 'CASH',
  CREDIT_CARD: 'CREDIT_CARD',
  ROOM: 'ROOM',
};

export const PaymentMethodType = {
  CASH: 'CASH',
  CREDIT_CARD: 'CREDIT_CARD',
  HOTEL_ROOM: 'HOTEL_ROOM',
  INVITATION: 'INVITATION',
};

export const Allergen = {
  0: { icon: 'Gluten', label: 'Contiene gluten' },
  1: { icon: 'Crustaceos', label: 'Crustáceos' },
  2: { icon: 'Huevo', label: 'Huevos' },
  3: { icon: 'Pescado', label: 'Pescado' },
  4: { icon: 'Cacahuetes', label: 'Cacahuetes' },
  5: { icon: 'Soja', label: 'Soja' },
  6: { icon: 'Lacteos', label: 'Lácteos' },
  7: { icon: 'Frutos_de_cascara', label: 'Frutos de cascara' },
  8: { icon: 'Apio', label: 'Apio' },
  9: { icon: 'Mostaza', label: 'Mostaza' },
  10: { icon: 'Sesamo', label: 'Granos de sésamo' },
  11: { icon: 'Dioxido_de_azufre_y_sulfitos', label: 'Dióxido de azufre y sulfitos' },
  12: { icon: 'Moluscos', label: 'Moluscos' },
  13: { icon: 'Almatruces', label: 'Altramuces' },
};

export const AllergenDataSource = Object.entries(Allergen).map(([value, { label, icon }]) => ({
  value: Number(value),
  label,
  icon: `/img/allergens/${icon}.png`,
}));

export const UnitType = {
  UD: 'ud.',
  L: 'l.',
  ML: 'ml.',
  G: 'g.',
  KG: 'kg.',
};

export const ReservationStatus = {
  REQUESTED: 'REQUESTED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
};

export const ReservationStatusDataSource = [
  { label: 'Solicitado', value: ReservationStatus.REQUESTED },
  { label: 'Aceptado', value: ReservationStatus.ACCEPTED },
  { label: 'Rechazado', value: ReservationStatus.REJECTED },
];
