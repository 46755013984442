import { lazy } from 'react';
import { AppModel, AppRole } from './api';
import { EstablishmentType, ReservationStatus, TpvType } from '.';
import { AppExtension } from './extensions';

export const AppRoutes = {
  '': {
    title: 'Inicio',
    component: lazy(() => import('../pages/Dashboard')),
    hidden: true,
  },

  settings: {
    title: 'Configuración',
    image: 'settings',
    isDashboard: true,
    component: lazy(() => import('../pages/Dashboard')),
    roles: [AppRole.ADMIN],
    pages: {
      tpv: {
        title: 'TPV',
        image: 'pos',
        component: lazy(() => import('../pages/Settings/Tpvs')),
        roles: [AppRole.ADMIN],
      },
      paymentMethods: {
        title: 'Métodos de pago',
        image: 'wallet',
        component: lazy(() => import('../pages/Settings/PaymentMethods')),
        roles: [AppRole.ADMIN],
      },
      identifications: {
        title: 'Identificaciones',
        image: 'identification',
        component: lazy(() => import('../pages/Settings/Identifications')),
        roles: [AppRole.ADMIN],
      },
      tables: {
        title: 'Mesas',
        image: 'chair',
        component: lazy(() => import('../pages/Restaurant/Tables')),
        roles: [AppRole.ADMIN],
        tpvTypes: [TpvType.RESTAURANT],
      },
      users: {
        title: 'Usuarios',
        image: 'users',
        component: lazy(() => import('../pages/Settings/Users')),
        roles: [AppRole.ADMIN],
      },

      appExtension: {
        title: 'Extensiones',
        image: 'extensions',
        component: lazy(() => import('../pages/Settings/Extensions')),
        roles: [AppRole.ADMIN],
        pages: {
          [AppExtension.RESOURCES]: {
            title: 'Recursos',
            image: 'resources',
            component: lazy(() => import('../pages/Extension/Resources')),
            appExtension: [AppExtension.RESOURCES],
            roles: [AppRole.ADMIN],
          },
          [AppExtension.REDSYS]: {
            title: 'Redsys',
            image: 'resources',
            component: lazy(() => import('../pages/Extension/Redsys')),
            appExtension: [AppExtension.REDSYS],
            roles: [AppRole.ADMIN],
          },
          [AppExtension.QUOHOTEL]: {
            title: 'QuoHotel',
            image: 'quohotel',
            component: lazy(() => import('../pages/Extension/QuoHotel')),
            appExtension: [AppExtension.QUOHOTEL],
            roles: [AppRole.ADMIN],
          },
          [AppExtension.BUSINESS_CENTRAL]: {
            title: 'Business Central',
            image: 'resources',
            component: lazy(() => import('../pages/Extension/BusinessCentral')),
            appExtension: [AppExtension.BUSINESS_CENTRAL],
            roles: [AppRole.ADMIN],
          },
          [AppExtension.PMS]: {
            title: 'PMS',
            image: 'resources',
            component: lazy(() => import('../pages/Extension/Pms')),
            appExtension: [AppExtension.PMS],
            roles: [AppRole.ADMIN],
          },
          [AppExtension.TURNS]: {
            title: 'Turnos',
            image: 'turns',
            component: lazy(() => import('../pages/Extension/Turns')),
            appExtension: [AppExtension.TURNS],
            roles: [AppRole.ADMIN],
          },
        },
      },
    },
  },

  hotel: {
    title: 'Hotel',
    image: 'hotel',
    isDashboard: true,
    component: lazy(() => import('../pages/Dashboard')),
    roles: [AppRole.ADMIN],
    establishmentTypes: [EstablishmentType.HOTEL],
    pages: {
      rooms: {
        title: 'Habitaciones',
        image: 'room',
        component: lazy(() => import('../pages/Hotel/Rooms')),
        roles: [AppRole.ADMIN],
      },
      guests: {
        title: 'Huéspedes',
        image: 'people',
        component: lazy(() => import('../pages/Hotel/Guests')),
        roles: [AppRole.ADMIN],
      },
      regimes: {
        title: 'Regimenes',
        image: 'regime',
        component: lazy(() => import('../pages/Hotel/Regimes')),
        roles: [AppRole.ADMIN],
      },
    },
  },

  restaurant: {
    title: 'Restaurante',
    isDashboard: true,
    component: lazy(() => import('../pages/Dashboard')),
    tpvTypes: [TpvType.RESTAURANT],
    image: 'eat tool',
    pages: {
      cart: {
        title: 'Carta',
        image: 'menu',
        component: lazy(() => import('../pages/Restaurant/Cart')),
        roles: [AppRole.ADMIN],
      },
      hall: {
        title: 'Punto de venta',
        image: 'chair',
        component: lazy(() => import('../pages/Restaurant/Hall')),
        roles: [AppRole.ADMIN, AppRole.WAITER, AppRole.WAITER_MASTER],
      },
      reservations: {
        title: 'Reservas',
        image: 'reservation',
        component: lazy(() => import('../pages/Restaurant/Reservations')),
        count: {
          model: AppModel.RESERVATION,
          filter: (x) => x.status === ReservationStatus.REQUESTED,
        },
      },
      tickets: {
        title: 'Tickets',
        image: 'bill',
        component: lazy(() => import('../pages/Tickets')),
        roles: [AppRole.ADMIN, AppRole.WAITER, AppRole.WAITER_MASTER],
      },
      cashReport: {
        title: 'Reporte de caja',
        image: 'cash-report',
        component: lazy(() => import('../pages/CashReport')),
        roles: [AppRole.ADMIN, AppRole.MANAGER, AppRole.WAITER],
      },
      reports: {
        title: 'Informes',
        image: 'report',
        component: lazy(() => import('../pages/Restaurant/Reports')),
        roles: [AppRole.ADMIN],
        appExtension: [AppExtension.REPORTS],
      },
    },
  },
  coffee: {
    title: 'Cafetería',
    isDashboard: true,
    component: lazy(() => import('../pages/Dashboard')),
    tpvTypes: [TpvType.COFFEE],
    image: 'coffee',
    pages: {
      cart: {
        title: 'Carta',
        image: 'menu',
        component: lazy(() => import('../pages/Restaurant/Cart')),
        roles: [AppRole.ADMIN],
      },
      sale: {
        title: 'Punto de venta',
        image: 'sign',
        component: lazy(() => import('../pages/CoffeeShop/SalePoint')),
        roles: [AppRole.ADMIN, AppRole.EMPLOYEE, AppRole.MANAGER],
      },
      tickets: {
        title: 'Tickets',
        image: 'bill',
        component: lazy(() => import('../pages/Tickets')),
        roles: [AppRole.ADMIN, AppRole.WAITER, AppRole.WAITER_MASTER],
      },
      reports: {
        title: 'Informes',
        image: 'report',
        component: lazy(() => import('../pages/Restaurant/Reports')),
        roles: [AppRole.ADMIN],
        appExtension: [AppExtension.REPORTS],
      },
    },
  },

  kitchen: {
    title: 'Cocina',
    image: 'chefhat',
    component: lazy(() => import('../pages/Restaurant/Kitchen')),
    tpvTypes: [TpvType.RESTAURANT],
    roles: [AppRole.ADMIN, AppRole.CHEFF, AppRole.WAITER, AppRole.WAITER_MASTER],
    count: {
      model: AppModel.ORDER,
      filter: (order) => {
        return (order.lines ?? []).some((x) => x.servedQuantity < x.quantity);
      },
    },
  },

  shop: {
    title: 'Tienda',
    image: 'shop',
    isDashboard: true,
    component: lazy(() => import('../pages/Dashboard')),
    tpvTypes: [TpvType.SHOP],
    pages: {
      products: {
        title: 'Productos',
        image: 'package',
        component: lazy(() => import('../pages/Shop/Products')),
        roles: [AppRole.ADMIN],
      },
      sale: {
        title: 'Punto de venta',
        image: 'sign',
        component: lazy(() => import('../pages/Shop/SalePoint')),
        roles: [AppRole.ADMIN, AppRole.EMPLOYEE, AppRole.MANAGER],
      },
      cashReport: {
        title: 'Reporte de caja',
        image: 'cash-report',
        component: lazy(() => import('../pages/CashReport')),
        roles: [AppRole.ADMIN, AppRole.MANAGER],
      },
      tickets: {
        title: 'Tickets',
        image: 'bill',
        component: lazy(() => import('../pages/Tickets')),
        roles: [AppRole.ADMIN, AppRole.MANAGER, AppRole.EMPLOYEE],
      },
    },
  },

  spa: {
    title: 'SPA',
    image: 'spa',
    isDashboard: true,
    component: lazy(() => import('../pages/Dashboard')),
    tpvTypes: [TpvType.SPA],
    pages: {
      products: {
        title: 'Productos',
        image: 'package',
        component: lazy(() => import('../pages/Shop/Products')),
        roles: [AppRole.ADMIN],
      },
      sale: {
        title: 'Punto de venta',
        image: 'sign',
        component: lazy(() => import('../pages/Shop/SalePoint')),
      },
      reservations: {
        title: 'Reservas',
        image: 'reservation',
        component: lazy(() => import('../pages/SPA/Reservations')),
        count: {
          model: AppModel.SPA_RESERVATION,
          filter: (x) => x.status === ReservationStatus.REQUESTED,
        },
        pages: {
          diary: {
            title: 'Agenda',
            image: 'calendar',
            component: lazy(() => import('../pages/SPA/Reservations/Diary')),
          },
        },
      },
      turns: {
        title: 'Turnos',
        image: 'turns',
        component: lazy(() => import('../pages/SPA/Turns')),
        roles: [AppRole.ADMIN],
        appExtension: [AppExtension.TURNS],
      },
      tickets: {
        title: 'Tickets',
        image: 'bill',
        component: lazy(() => import('../pages/Tickets')),
      },
      cashReport: {
        title: 'Reporte de caja',
        image: 'cash-report',
        component: lazy(() => import('../pages/CashReport')),
        roles: [AppRole.ADMIN, AppRole.MANAGER],
      },
      reports: {
        title: 'Informes',
        image: 'report',
        component: lazy(() => import('../pages/SPA/Reports')),
        roles: [AppRole.ADMIN],
        appExtension: [AppExtension.REPORTS],
      },
    },
  },
  //#region EXTENSIONS
  businessCentral: {
    title: 'Business Central',
    image: 'businessCentral',
    isDashboard: true,
    component: lazy(() => import('../pages/Dashboard')),
    appExtension: [AppExtension.BUSINESS_CENTRAL],
    pages: {
      emmitedInvoices: {
        title: 'Facturas emitidas',
        image: 'bill',
        component: lazy(() => import('../pages/EmmitedInvoices')),
      },
    },
  },
  warehouse: {
    title: 'Almacén',
    image: 'warehouse',
    component: lazy(() => import('../pages/Extension/Warehouse')),
    appExtension: [AppExtension.WAREHOUSE],
    pages: {
      items: {
        title: 'Artículos',
        component: lazy(() => import('../pages/Extension/Warehouse/Items')),
      },
    },
  },
  //#endregion
};
