// export const API_URL = 'http://localhost:3005';
export const API_URL = 'https://vw-api.kuarasoftware.es';

// export const KUARA_APPS_URL = 'http://localhost:3001';
export const KUARA_APPS_URL = 'https://kuara-apps.kuarasoftware.es';

export const AppModel = {
  // GENERAL
  TPV: 'tpv',
  USER: 'user',
  PAYMENT_METHOD: 'paymentMethod',
  IDENTIFICATION: 'identification',
  ITEM_TYPE: 'itemType',
  PRODUCTION_CENTER: 'productionCenter',
  REGIME: 'regime',

  // HOTEL
  HOTEL_ROOM: 'hotelRoom',
  HOTEL_RESERVATION: 'hotelReservation',

  // SHOP
  PRODUCT: 'product',
  WORKING_DAY: 'workingDay',
  STOCK: 'stock',

  // RESTAURANT
  PLATE: 'plate',
  ZONE: 'zone',
  TABLE: 'table',
  ORDER: 'order',
  RESERVATION: 'reservation',
  TICKET: 'ticket',

  // SPA
  SPA_RESERVATION: 'spaReservation',

  // EXTENSIONS
  APP_EXTENSION: 'appExtension',

  // EXTENSION: Resources
  RESOURCE: 'resource',

  // EXTENSION: Diary
  EVENT: 'event',

  // EXTENSION: Warehouse
  WAREHOUSE: 'warehouse',
  WAREHOUSE_ITEM: 'warehouseItem',

  // EXTENSION: PMS
  GUEST: 'guest',

  // EXTENSION: Turns
  TURN: 'turn',
};

export const AppRole = {
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  EMPLOYEE: 'EMPLOYEE',
  CHEFF: 'CHEFF',
  WAITER: 'WAITER',
};

export const AppRoleCaptions = {
  [AppRole.ADMIN]: 'Admin',
  [AppRole.WAITER]: 'Camarero',
  [AppRole.CHEFF]: 'Cocinero',
  // [AppRole.EMPLOYEE]: "Empleado",
  // [AppRole.MANAGER]: "Encargado"
};

// Ticket types
export const TicketType = {
  PREVIOUS: 'PREVIOUS',
  FINAL: 'FINAL',
};

export const RESTAURANT_ROLES = {
  ADMIN: {
    label: 'Admin',
    value: 'ADMIN',
    color: 'red',
  },
  WAITER: {
    label: 'Camarero',
    value: 'WAITER',
    color: 'blue',
  },
  CHEFF: {
    label: 'Cocinero',
    value: 'CHEFF',
    color: 'gold',
  },
};

export const RESTAURANT_ROLES_LIST = Object.values(RESTAURANT_ROLES);

export const SHOP_ROLES = {
  ADMIN: {
    label: 'Admin',
    value: 'ADMIN',
    color: 'red',
  },
  MANAGER: {
    label: 'Encargado',
    value: 'MANAGER',
    color: 'gold',
  },
  EMPLOYEE: {
    label: 'Empleado',
    value: 'EMPLOYEE',
    color: 'geekblue',
  },
};

export const SHOP_ROLES_LIST = Object.values(SHOP_ROLES);

export const TranslationLangs = {
  GB: 'Inglés',
  IT: 'Italiano',
  FR: 'Francés',
  DE: 'Alemán',
};
