import axios from 'axios';

export const Actions = {
  /**
   *
   * @param {String} url
   * @param {any} filter
   * @param {{
   *  skip: Number
   *  take: Number
   * }} paginate
   * @returns
   */
  get: async (url, filter, paginate) =>
    await axios
      .get(url, {
        params: {
          filter: JSON.stringify(filter),
          ...(paginate ? { paginate: true, ...paginate } : {}),
        },
      })
      .then((x) => x.data)
      .catch(() => null),
  post: async (url, data) =>
    await axios
      .post(url, data)
      .then((x) => x.data)
      .catch(() => null),
  put: async (url, data) =>
    await axios
      .put(url, data)
      .then((x) => x.data)
      .catch(() => null),
  delete: async (url, data) =>
    await axios
      .delete(url, { data })
      .then(() => true)
      .catch(() => false),
};
