import { useEffect } from 'react';
import { useApp, useStore } from '../../store/appStores';
import { TpvType } from '../../constants';
import { Actions } from '../../actions';
import { AppModel } from '../../constants/api';
import { AppExtension } from '../../constants/extensions';
import { useCurrentTpv } from './useCurrentTpv';

export const useFetchTpvData = () => {
  const set = useApp((s) => s.set);
  const establishment = useApp((s) => s.establishment);

  const currentTpv = useCurrentTpv();

  useEffect(() => {
    const tpvType = currentTpv?.type;
    const fetchData = async () => {
      if (!tpvType) return;
      set({ loading: true });

      await fetchModelsData({ models: [[AppModel.ITEM_TYPE, { tpv: currentTpv?._id }]], set });
      await fetchModelsData({ models: tpvModels[tpvType], set });

      if (establishment?.type) {
        await fetchModelsData({ models: [AppModel.GUEST], set });
      }

      set({ loading: false });
    };

    fetchData();
  }, [currentTpv?.type, currentTpv?._id, establishment?.type, set]);

  useFetchExtensions();
};

const useFetchExtensions = () => {
  const set = useApp((s) => s.set);
  const selectedTpv = useApp((s) => s.selectedTpv);
  const extensions = useStore(AppModel.APP_EXTENSION);

  useEffect(() => {
    if (!extensions) return;

    for (const { key } of extensions) {
      const models = extensionModels[key];
      if (!models) continue;

      fetchModelsData({ models, set });
    }
  }, [extensions, set, selectedTpv]);
};

const tpvModels = {
  [TpvType.RESTAURANT]: [
    AppModel.PLATE,
    AppModel.ORDER,
    AppModel.ZONE,
    AppModel.TABLE,
    [AppModel.TICKET, { type: 'PREVIOUS' }],
    AppModel.RESERVATION,
  ],
  [TpvType.COFFEE]: [AppModel.PLATE, AppModel.ZONE],
  [TpvType.SHOP]: [AppModel.PRODUCT],
  [TpvType.SPA]: [AppModel.PRODUCT, AppModel.SPA_RESERVATION],
};

const extensionModels = {
  [AppExtension.RESOURCES]: [AppModel.RESOURCE],
  [AppExtension.WAREHOUSE]: [AppModel.WAREHOUSE, AppModel.WAREHOUSE_ITEM],
  [AppExtension.QUOHOTEL]: [AppModel.PRODUCTION_CENTER],
};

const fetchModelsData = async ({ models, set }) => {
  const httpRequests = models.map((m) => Actions.get(...(m instanceof Array ? m : [m])));
  await Promise.all(httpRequests).then((data) => {
    data.forEach((modelData, modelIndex) => {
      const model = models[modelIndex];
      const modelName = model instanceof Array ? model[0] : model;

      set({ [modelName]: modelData ?? [] });
    });
  });
};
