import styled from 'styled-components';
import { ListItem } from './ListItem';

export const CategoriesList = ({ dataSource = [], onClickItem }) => {
  return (
    <Wrapper>
      <Container>
        {dataSource.map((item) => {
          return <ListItem key={item._id} item={item} onClick={onClickItem} />;
        })}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  overflow: hidden auto;
`;

const Container = styled.section`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 1;
`;
