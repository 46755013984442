import { Typography } from 'antd';
import { useApp } from '../../store/appStores';
import { t } from '../../components/LangProvider';
import { useCurrentTpv } from '../../utils/hooks/useCurrentTpv';
import { NoServicesCreated } from './NoServicesCreated';
import { CategoriesList } from '../../components/CategoriesList';

export const SelectService = () => {
  const set = useApp((s) => s.set);
  const profile = useApp((s) => s.profile);

  const { services } = useCurrentTpv();

  if (!(services ?? []).length) {
    return <NoServicesCreated />;
  }

  return (
    <>
      <Typography.Title style={{ textAlign: 'center' }}>{profile.name},</Typography.Title>
      <Typography.Title type="secondary" level={2} style={{ textAlign: 'center' }}>
        {t('Selecciona un servicio')}
      </Typography.Title>
      <CategoriesList dataSource={services} onClickItem={({ _id }) => set({ selectedService: _id })} />
    </>
  );
};
