import { useEffect } from 'react';
import { useApp, useStore } from '../../store/appStores';
import { useCurrentTpv } from './useCurrentTpv';
import { AppModel } from '../../constants/api';

export const useFindWorkingDay = () => {
  const tpvs = useStore(AppModel.TPV);
  const set = useApp((s) => s.set);
  const currentTpv = useCurrentTpv();
  const userInfo = useApp((s) => s.userInfo);
  const workingDays = useStore(AppModel.WORKING_DAY);

  useEffect(() => {
    if (!(!currentTpv && userInfo.tpvs?.length === 1)) {
      return;
    }

    const selectedTpv = userInfo.tpvs?.[0] ?? null;
    const tpv = tpvs.find((x) => x._id === selectedTpv);

    const selectedService = tpv?.services?.length === 1 ? tpv.services[0]._id : null;

    const selectedWorkingDay = workingDays.find((x) => x.tpv === selectedTpv)?._id ?? null;

    if (selectedWorkingDay) localStorage.setItem('workingDay', selectedWorkingDay);
    else localStorage.removeItem('workingDay');

    set({ selectedTpv, selectedService, selectedWorkingDay });
  }, [userInfo, currentTpv, set, workingDays, tpvs]);
};
