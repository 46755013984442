import { useApp } from '../../store/appStores';
import { useCurrentTpv } from './useCurrentTpv';

export const useGetValidRoutes = () => {
  const establishment = useApp((s) => s.establishment);
  const userInfo = useApp((s) => s.userInfo);
  const currentTpv = useCurrentTpv();
  const enabledExtensions = useApp((s) => s.appExtension).map((x) => x.key);

  return (routes) => {
    return Object.entries(routes ?? {}).filter(([key, route]) => {
      const isVisible = !route.hidden;
      const hasValidRole = !route.roles || route.roles.includes(userInfo?.role);
      const hasValidTpv = !route.tpvTypes || route.tpvTypes.includes(currentTpv?.type);
      const hasValidEstablishment = !route.establishmentTypes || route.establishmentTypes.includes(establishment?.type);

      const hasAccessToExtension =
        !route.appExtension || route.appExtension.some((extension) => enabledExtensions.includes(extension));

      return isVisible && hasValidRole && hasValidTpv && hasAccessToExtension && hasValidEstablishment;
    });
  };
};
