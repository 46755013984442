import styled from 'styled-components';

export const CategoriesWrapper = styled.section`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 1;
  height: 100%;
  overflow: hidden auto;
`;

export const FlexSpace = styled.div`
  display: flex;
  ${({ minwidth }) => !minwidth && 'width: 100%;'}
  flex-wrap: ${({ nowrap }) => (nowrap ? 'nowrap' : 'wrap')};
  justify-content: space-between;
  align-items: ${({ align }) => align ?? 'space-between'};
  gap: 8px;
  ${({ margin }) => (margin ? 'margin-bottom: 8px;' : '')}

  ${({ nogrow }) =>
    !nogrow &&
    `& > * {
    flex-grow: 1;
  }
  width 100%;
  overflow: hidden;
  .ant-input-number {
    width: 100%;
  }`}

  

  span.anticon-minus-circle {
    margin-bottom: 8px;
  }
`;

export const Toolbar = styled.div`
  display: flex;
  gap: 8px;
  padding: 4px 8px;
`;
