import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  TimePicker,
  Upload,
} from 'antd';
import { CheckOutlined, FileImageOutlined } from '@ant-design/icons';
import { t } from '../LangProvider';
import { getBase64 } from '../../utils';
import dayjs from 'dayjs';
import { useState } from 'react';
import { DeleteButton } from '..';
import axios from 'axios';

const RULES = [{ required: true }];

export const FormField = {
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  Text: ({ required, label, placeholder, readOnly, ...props }) => {
    const rules = props.rules ?? [];

    if (required) rules.push(...RULES);

    return (
      <Form.Item {...props} label={t(label)} rules={rules}>
        <Input disabled={readOnly} placeholder={`${t(placeholder ?? label ?? '')}...`} />
      </Form.Item>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  Number: ({ required, label, placeholder, ...props }) => {
    return (
      <Form.Item {...props} label={t(label)} rules={required && RULES}>
        <InputNumber style={{ width: '100%' }} placeholder={`${t(placeholder ?? label ?? '')}...`} />
      </Form.Item>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  TextArea: ({ required, label, placeholder, ...props }) => {
    return (
      <Form.Item {...props} label={t(label)} rules={required && RULES}>
        <Input.TextArea placeholder={`${t(placeholder ?? label ?? '')}...`} />
      </Form.Item>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  Date: ({ required, label, placeholder, ...props }) => {
    return (
      <Form.Item
        {...props}
        label={t(label)}
        rules={required && RULES}
        getValueProps={(i) => {
          return { value: i ? dayjs(typeof i === 'string' ? i : i?.$d) : undefined };
        }}
      >
        <DatePicker format={'DD/MM/YYYY'} placeholder={`${t(placeholder ?? label ?? '')}...`} />
      </Form.Item>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  DateTime: ({ required, label, placeholder, rules = [], ...props }) => {
    if (required) {
      rules.push(...RULES);
    }

    return (
      <Form.Item noStyle shouldUpdate={() => false}>
        {({ setFieldsValue }) => {
          return (
            <Form.Item
              {...props}
              label={t(label)}
              rules={rules}
              getValueProps={(i) => {
                return { value: i ? dayjs(i?.$d) : undefined };
              }}
            >
              <DatePicker
                onSelect={(value) => setFieldsValue({ [props.name]: value })}
                showTime={true}
                format={'DD/MM/YYYY HH:mm'}
                placeholder={`${t(placeholder ?? label ?? '')}...`}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  Time: ({ required, label, placeholder, timeProps, ...props }) => {
    return (
      <Form.Item
        {...props}
        label={t(label)}
        rules={required && RULES}
        getValueProps={(i) => {
          return { value: i ? dayjs(typeof i === 'string' ? i : i?.$d) : undefined };
        }}
      >
        <TimePicker placeholder={`${t(placeholder ?? label ?? '')}...`} {...timeProps} format={'HH:mm'} />
      </Form.Item>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  Password: ({ required, label, placeholder, ...props }) => {
    return (
      <Form.Item {...props} label={t(label)} rules={required && RULES}>
        <Input.Password placeholder={`${t(placeholder ?? label ?? '')}...`} autoComplete="new-password" />
      </Form.Item>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  Select: ({ dataSource = [], label, placeholder, ...props }) => {
    return (
      <Form.Item {...props} label={t(label)} rules={props.required && RULES}>
        <Select
          placeholder={`${t(placeholder ?? label ?? '')}...`}
          allowClear={true}
          showSearch={true}
          optionFilterProp="label"
          options={dataSource}
        />
      </Form.Item>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  MultiSelect: ({ required, dataSource = [], label, placeholder, ...props }) => {
    return (
      <Form.Item {...props} label={t(label)} rules={required && RULES}>
        <Select mode="multiple" placeholder={`${t(placeholder ?? label ?? '')}...`} options={dataSource} />
      </Form.Item>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  CheckboxGroup: ({ required, dataSource = [], label, placeholder, ...props }) => {
    return (
      <Form.Item {...props} label={t(label)} rules={required && RULES}>
        <Checkbox.Group options={dataSource} />
      </Form.Item>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  Radio: ({ required, dataSource = [], label, ...props }) => {
    return (
      <Form.Item {...props} label={t(label)} rules={required && RULES}>
        <Radio.Group optionType="button" options={dataSource.map((x) => ({ ...x, label: t(x.label) }))} />
      </Form.Item>
    );
  },
  Submit: ({ text = 'Aceptar', loading, style }) => {
    return (
      <Button
        htmlType="submit"
        icon={<CheckOutlined />}
        style={{ width: '100%', ...style }}
        type="primary"
        loading={loading}
      >
        {t(text)}
      </Button>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  Switch: ({ required, label, ...props }) => {
    return (
      <Form.Item {...props} label={t(label)} rules={required && RULES} valuePropName="checked">
        <Switch />
      </Form.Item>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  Image: ({ required, label, ...props }) => {
    const [isTemp, setIsTemp] = useState(false);

    return (
      <Form.Item noStyle shouldUpdate={(prev, curr) => prev[props.name] !== curr[props.name]}>
        {({ setFieldValue, getFieldValue, setFieldsValue }) => {
          const value = getFieldValue(props.name);
          return (
            <>
              <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                <Upload
                  listType="picture-card"
                  beforeUpload={async (file) => {
                    const newFile = await getBase64(file);
                    setFieldValue(props.name, newFile);
                    setIsTemp(true);
                    return false;
                  }}
                  multiple={false}
                  maxCount={1}
                  showUploadList={false}
                  accept="image/*"
                  style={{ display: 'block', textAlign: 'center' }}
                >
                  {value ? (
                    <img
                      src={isTemp ? value : `${value}?${Date.now()}`}
                      alt=""
                      style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                    />
                  ) : (
                    <div>
                      <FileImageOutlined style={{ display: 'block' }} />
                      {t('Foto')}
                    </div>
                  )}
                </Upload>
                <DeleteButton
                  text="Foto"
                  style={{ width: '' }}
                  onConfirm={async () => {
                    const imageId = getFieldValue('imageId');

                    await axios.delete(`https://kuara-storage.kuarasoftware.es/upload/files/${imageId}`, {
                      headers: {
                        Authorization: '',
                      },
                    });

                    setFieldsValue({
                      imageUrl: null,
                      imageId: null,
                    });
                  }}
                />
              </div>

              <Form.Item {...props} label={t(label)} rules={required && RULES} hidden={true}>
                <Input />
              </Form.Item>
            </>
          );
        }}
      </Form.Item>
    );
  },
  /**
   *
   * @param {import('antd/es/form').FormItemProps} props
   * @returns
   */
  Color: ({ required, label, ...props }) => {
    return (
      <Form.Item {...props} label={t(label)} rules={required && RULES}>
        <Input type="color" />
      </Form.Item>
    );
  },
};
