import { SwapOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useApp } from '../../store/appStores';

export const UnselectTpvButton = () => {
  const userInfo = useApp((s) => s.userInfo);
  const set = useApp((s) => s.set);

  const unselectTpv = () => {
    set({
      selectedTpv: null,
      selectedWorkingDay: null,
      selectedService: null,
      ignoreClosedWorkingDay: false,
    });
  };

  if ((userInfo?.tpvs ?? []).length === 1) {
    return null;
  }

  return (
    <Button icon={<SwapOutlined />} onClick={unselectTpv}>
      Cambiar de TPV
    </Button>
  );
};
