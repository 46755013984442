import { Button, Result } from 'antd';
import { useNavigate } from 'react-router';
import { AppRole } from '../../constants/api';
import { useApp } from '../../store/appStores';

export const NoServicesCreated = () => {
  const navigate = useNavigate();
  const userInfo = useApp((s) => s.userInfo);

  return (
    <Result
      status={403}
      title="Ups"
      subTitle="No hay servicios configurados para el TPV seleccionado"
      extra={
        userInfo.role === AppRole.ADMIN ? <Button onClick={() => navigate('/settings/tpv')}>Configurar</Button> : null
      }
    />
  );
};
