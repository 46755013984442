import { DeleteFilled, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { t } from './LangProvider';
import styled from 'styled-components';

export const NewButton = ({ text = 'Crear', onClick }) => {
  return (
    <Button type="primary" icon={<PlusCircleOutlined />} onClick={onClick}>
      {t(text)}
    </Button>
  );
};
export const DeleteButton = ({ text = 'Eliminar', onConfirm, style }) => {
  return (
    <Popconfirm title={t('¿Está seguro?')} onConfirm={onConfirm}>
      <Button type="dashed" danger icon={<DeleteFilled />} style={{ width: '100%', ...style }}>
        {t(text).toUpperCase()}
      </Button>
    </Popconfirm>
  );
};

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  button {
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
