import { Button, Layout, Spin, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useCallback, useMemo } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AppRoutes } from '../../constants/routes';
import { t } from '../LangProvider';
import { useApp } from '../../store/appStores';
import { ServiceSelector } from './ServiceSelector';
import { useFetchTpvData } from '../../utils/hooks/useFetchTpvData';
import { AppForm } from '../Forms/AppForm';
import { ProfileButton } from './ProfileButton';
import { useCurrentTpv } from '../../utils/hooks/useCurrentTpv';
import { PrintingInvoiceForm } from '../PrintingInvoiceForm';
import { SyncQuoHotelButton } from './SyncQuoHotelButton';
import { useCurrentWorkingDay } from '../../utils/hooks/useCurrentWorkingDay';
import dayjs from 'dayjs';

const { Content, Header } = Layout;

export const MainLayout = (props) => {
  const { children } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isMobile = useApp((s) => s.isMobile);

  const currentTpv = useCurrentTpv();
  const isLoading = useApp((s) => s.loading);
  const selectedTpv = useApp((s) => s.selectedTpv);
  const selectedWorkingDay = useApp((s) => s.selectedWorkingDay);

  const handleBack = useCallback(() => {
    navigate('..', { relative: 'path' });
  }, [navigate]);

  const subTitle = useRouteTitle();

  const isHome = pathname === '/';
  const isSettings = pathname.toLowerCase().startsWith('/settings');

  const workingDay = useCurrentWorkingDay();

  useFetchTpvData();

  return (
    <Layout>
      <AppHeader>
        {isHome ? null : <Button onClick={handleBack} type="text" icon={<LeftOutlined style={{ color: 'white' }} />} />}
        {isMobile ? null : <Typography.Title level={2}>{subTitle}</Typography.Title>}
        {isLoading ? <Spin style={{ marginLeft: 'auto' }} /> : <div style={{ marginLeft: 'auto' }} />}
        {currentTpv ? (
          <div className="workingday">
            <div>{currentTpv.name}</div>
            <div>{workingDay ? dayjs(workingDay.openDate).format('DD/MM/YYYY') : null}</div>
          </div>
        ) : null}
        {selectedTpv && (currentTpv?.services ?? []).length > 1 ? <ServiceSelector /> : null}
        <SyncQuoHotelButton />
        <ProfileButton />
      </AppHeader>
      <AppContent pathname={pathname}>
        {!selectedWorkingDay && !isHome && !isSettings ? <Navigate to="/" /> : children}
      </AppContent>
      <AppForm />
      <PrintingInvoiceForm />
    </Layout>
  );
};

const useRouteTitle = () => {
  const { pathname } = useLocation();

  return useMemo(() => {
    const [, ...steps] = pathname.split('/');
    let step = steps.shift();
    let route = AppRoutes[step];

    while (steps.length) {
      step = steps.shift();
      route = route?.pages?.[step];
    }

    return t(route?.title);
  }, [pathname]);
};

const AppContent = styled(Content)`
  height: calc(100vh - 72px);
  overflow: hidden auto;
  padding: 0;
`;

const AppHeader = styled(Header)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
  background: #c27;

  .workingday {
    display: grid;
    max-height: 100%;
    gap: 0;
    color: white;
    justify-items: center;

    & > div {
      line-height: 1;
    }
  }

  h1,
  h2 {
    margin: 0;
    color: white;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1rem;
  }
`;
