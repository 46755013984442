import { useCallback, useEffect } from 'react';
import { useApp } from '../../store/appStores';

export const useIsMobile = () => {
  const set = useApp((s) => s.set);
  const handleWindowSizeChange = useCallback(() => set({ isMobile: window.innerWidth <= 768 }), [set]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);
};
