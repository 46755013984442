import { Result } from 'antd';
import { DashboardActionsWrapper } from '.';
import { OpenWorkingDayButton } from './OpenWorkingDayButton';
import { UnselectTpvButton } from './UnselectTpvButton';

export const OpenWorkingDay = () => {
  return (
    <Result
      status="403"
      title="No hay caja abierta para el TPV seleccionado"
      extra={
        <DashboardActionsWrapper>
          <OpenWorkingDayButton />
          <UnselectTpvButton />
        </DashboardActionsWrapper>
      }
    />
  );
};
