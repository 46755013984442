export const AppExtension = {
  DIARY: 'DIARY',
  BUSINESS_CENTRAL: 'BUSINESS_CENTRAL',
  REDSYS: 'REDSYS',
  RESOURCES: 'RESOURCES',
  PRINTING: 'PRINTING',
  WAREHOUSE: 'WAREHOUSE',
  REPORTS: 'REPORTS',
  PMS: 'PMS',
  QUOHOTEL: 'QUOHOTEL',
  TURNS: 'TURNS',
};

export const AppExtensionData = {
  // [AppExtension.DIARY]: {
  //   title: 'Agenda',
  //   description: '...',
  //   image: 'calendar',
  // },
  [AppExtension.QUOHOTEL]: {
    title: 'QuoHotel',
    description: 'Sincroniza tus datos de QuoHotel',
    hasConfig: true,
    image: 'quohotel',
  },
  // [AppExtension.BUSINESS_CENTRAL]: {
  //   title: 'Business Central (Dynamics 365)',
  //   description: 'Permite la sincronización con Business Central.',
  //   hasConfig: true,
  //   image: 'businessCentral',
  // },
  [AppExtension.REDSYS]: {
    title: 'Redsys (Pago online)',
    description: 'Permite el pago online de tus clientes desde la aplicación.',
    hasConfig: true,
    image: 'redsys',
  },
  [AppExtension.RESOURCES]: {
    title: 'Recursos',
    description:
      'Crea recursos y asocialos a tus reservas para saber del material a utilizar a la hora de atender al cliente',
    hasConfig: true,
    image: 'resources',
  },
  [AppExtension.PRINTING]: {
    title: 'Impresión',
    description: 'Añade el sistema de impresión de tickets y/o comandas.',
    image: 'printer',
  },
  // [AppExtension.WAREHOUSE]: {
  //   title: 'Almacén',
  //   description: 'Sepa en todo momento de cuántas existencias restantes dispone.',
  //   image: 'warehouse',
  // },
  [AppExtension.REPORTS]: {
    title: 'Informes',
    description: '...',
    image: 'cash-report',
  },
  [AppExtension.TURNS]: {
    title: 'Turnos',
    description: 'Controla los turnos de tus empleados',
    image: 'turns',
    hasConfig: true,
  },
  [AppExtension.PMS]: {
    title: 'PMS',
    description: 'Activa la sincronización con nuestro PMS.',
    image: 'resources',
    hasConfig: true,
  },
};
