import { useSearchParams, useNavigate } from 'react-router-dom';
import { useApp } from '../../store/appStores';
import { useEffect } from 'react';
import axios from 'axios';

export const useLoadInitialData = () => {
  const [params] = useSearchParams();
  const set = useApp((s) => s.set);
  const dataLoaded = useApp((s) => s.dataLoaded);
  const appToken = useApp((s) => s.token);
  const loadData = useApp((s) => s.loadData);
  const navigate = useNavigate();

  const token = params.get('token') ?? localStorage.getItem('token');

  useEffect(() => {
    if (token && !appToken) {
      axios.defaults.headers = {
        Authorization: `Bearer ${token}`,
      };

      set({ token });
      localStorage.setItem('token', token);
    } else if (appToken && !dataLoaded) {
      localStorage.removeItem('username');
      localStorage.removeItem('password');
      loadData().then(() => navigate({ search: '' }, { replace: true }));
    }
  }, [dataLoaded, appToken, token, set, loadData, navigate]);
};
