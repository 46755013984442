import { LogoutOutlined, ProfileOutlined } from '@ant-design/icons';
import { Avatar, Dropdown } from 'antd';
import { KUARA_APPS_URL } from '../../constants/api';
import { useApp } from '../../store/appStores';
import { t } from '../LangProvider';

export const ProfileButton = () => {
  const profile = useApp((s) => s.profile);
  const token = useApp((s) => s.token);

  const handleLogout = () => {
    window.localStorage.removeItem('token');

    window.location.href = '/';
  };

  if (!profile) return null;

  return (
    <Dropdown
      menu={{
        items: [
          {
            label: t('Mi perfil'),
            icon: <ProfileOutlined />,
            onClick: () => window.open(`${KUARA_APPS_URL}/profile?token=${token}`, '__blank'),
          },
          { label: t('Cerrar sesión'), icon: <LogoutOutlined />, onClick: handleLogout },
        ],
      }}
    >
      <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
        <div style={{ color: 'white' }}>{profile?.username}</div>
        <Avatar src={profile?.picture?.url ?? '/img/logo.png'} />
      </div>
    </Dropdown>
  );
};
