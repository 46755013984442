import { Typography } from 'antd';
import { useApp, useStore } from '../../store/appStores';
import { AppModel } from '../../constants/api';
import { t } from '../../components/LangProvider';
import { CategoriesList } from '../../components/CategoriesList';

export const SelectTpv = () => {
  const set = useApp((s) => s.set);
  const profile = useApp((s) => s.profile);
  const userInfo = useApp((s) => s.userInfo);
  const tpvs = useStore(AppModel.TPV);
  const workingDays = useStore(AppModel.WORKING_DAY);

  const userTpvs = tpvs.filter((x) => userInfo.tpvs.includes(x._id));

  return (
    <>
      <Typography.Title style={{ textAlign: 'center' }}>{profile.name},</Typography.Title>
      <Typography.Title type="secondary" level={2} style={{ textAlign: 'center' }}>
        {t('Selecciona un TPV')}
      </Typography.Title>
      <CategoriesList
        dataSource={userTpvs}
        onClickItem={(tpv) => {
          const selectedTpv = tpv._id;
          const services = tpv.services ?? [];

          const selectedService = services.length === 1 ? services[0]._id : null;
          const selectedWorkingDay = workingDays.find((x) => x.tpv === selectedTpv)?._id ?? null;

          set({ selectedTpv, selectedWorkingDay, selectedService });
        }}
      />
    </>
  );
};
