import styled from 'styled-components';

export const ListItem = ({ item, onClick }) => {
  return (
    <Wrapper img={`${item?.imageUrl}?${Date.now()}`} onClick={() => onClick?.(item)}>
      <h1>{item.name}</h1>
    </Wrapper>
  );
};

const Wrapper = styled.article`
  ${({ img }) =>
    img &&
    `background-image: url(${img});background-position: center;background-size: cover; background-repeat: no-repeat;`}
  border: 1px solid #d1d1d1;
  height: 96px;
  width: 192px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  transition: transform 0.2s ease;
  position: relative;

  :hover {
    transform: scale(1.05);
  }

  ::after {
    content: '';
    background-color: #0005;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  h1 {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 8px;
    color: #fff;
    border-radius: 8px;
    z-index: 1;
  }

  ::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    background-color: rgba(0, 0, 0, 0.1);
  }

  :hover::before {
    display: block;
  }
`;
