import { Link } from 'react-router-dom';
import styledComponents from 'styled-components';
import { t } from '../LangProvider';
import { Typography } from 'antd';
import { useStore } from '../../store/appStores';

export const DashboardItem = ({ path, title, image = '', count }) => {
  const dataSource = useStore(count?.model);
  const totalCount = count ? dataSource.filter(count.filter).length : 0;

  return (
    <ItemWrapper to={path}>
      <article style={{ backgroundImage: image }}>
        {image ? <img src={image} alt={title} /> : null}
        <footer>
          <Typography.Title>{t(title)}</Typography.Title>
        </footer>
      </article>
      {totalCount ? <div className="badge">{totalCount}</div> : null}
    </ItemWrapper>
  );
};

const ItemWrapper = styledComponents(Link)`  
  color: unset;
  position: relative;
  transition: transform 0.2s ease;
  
  :hover {
    transform: scale(1.05);
  }

  article {
    width: 200px;
    border: 1px solid #d1d1d1;
    height: 192px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, .15);

    img {
      margin-top: 8px;
      height: calc(100% - 76px);
      width: 100%;
      object-fit: contain;
    }
  }

  footer {
    background: #fff;
    bottom: 0;
    position: absolute;
    width: 100%;
    text-transform: uppercase;
    height: 60px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      margin: 0;
      font-size: 1rem;
    }
  }

  .badge {
    position: absolute;
    top: -8px;
    right: -8px;
    background: green;
    padding: 2px 6px;
    border-radius: 8px;
    color: #ffffff;
    font-size: 18px;
  }
`;
