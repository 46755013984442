import { SyncOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, List, message } from 'antd';
import { useExtension } from '../../utils/hooks/useExtension';
import { AppExtension } from '../../constants/extensions';
import axios from 'axios';
import { useMemo, useState } from 'react';
import { useStore } from '../../store/appStores';
import { AppModel } from '../../constants/api';
import { Toolbar } from '../styles';

export const SyncQuoHotelButton = () => {
  const config = useExtension(AppExtension.QUOHOTEL);
  const rooms = useStore(AppModel.HOTEL_ROOM);
  const hotelReservations = useStore(AppModel.HOTEL_RESERVATION);
  const regimes = useStore(AppModel.REGIME);
  const [searchValue, setSearchValue] = useState('');

  const [open, setOpen] = useState(false);

  const reservations = useMemo(() => {
    return hotelReservations
      .filter((x) => x.status === 'ACTIVE')
      .map((item) => {
        const room = rooms.find((x) => x._id === item.room);

        return {
          guestName: `${item.surname}, ${item.name}`,
          roomName: room?.name,
          regime: item.regime,
        };
      });
  }, [hotelReservations, rooms]);

  const dataSource = useMemo(() => {
    const search = searchValue.toLowerCase();
    return reservations.filter((x) => {
      return x.guestName.toLowerCase().includes(search) || x.roomName.toLowerCase().includes(search);
    });
  }, [reservations, searchValue]);

  if (!config) {
    return null;
  }

  const handleSync = () => {
    message.loading({
      content: 'Sincronizando reservas',
      duration: 0,
      key: 'download-guests',
    });

    axios
      .post('/hotelReservation/sync/quoHotel')
      .then(async () => {
        message.success({
          content: 'Reservas sincronizadas',
          key: 'download-guests',
        });
      })
      .catch(() => {
        message.error({ content: 'No se han podido sincronizar las reservas', key: 'download-guests' });
      });
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>Ver huéspedes</Button>
      <Drawer title="Huéspedes" open={open} maskClosable={false} onClose={() => setOpen(false)}>
        <Toolbar style={{ padding: 0 }}>
          <Button icon={<SyncOutlined />} onClick={handleSync}>
            Actualizar
          </Button>
          <Input.Search value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
        </Toolbar>
        <List
          dataSource={dataSource}
          renderItem={(item) => {
            const regime = regimes.find((x) => x._id === item.regime)?.details?.quoHotel?.Oid;
            return (
              <List.Item>
                <List.Item.Meta
                  title={item.guestName}
                  description={`Hab. ${item.roomName} ${regime ? `(${regime})` : ''}`}
                />
              </List.Item>
            );
          }}
        />
      </Drawer>
    </>
  );
};
